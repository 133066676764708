import React, { useState, useEffect } from "react";
import SceneView from "components/SceneView";
import MusicPlayer from "components/MusicPlayer";
import PlayerUI from "components/PlayerUI";
import AssetDb from "components/AssetDb";
import { useDatabase } from "hooks/useDatabase";



import {
	Form,
	InputGroup,
	Container,
	Row,
	Col,
	Button,
	Image,
	Card,
	Alert,
	OverlayTrigger ,
	Popover
} from "react-bootstrap";

import {
	//getDatabase,
	ref,
	set,
	onDisconnect,
	onValue,
	onChildAdded,
	onChildRemoved,
	serverTimestamp,
} from "firebase/database";

const CONTRACT_ADDRESS = "1nc1nerator11111111111111111111111111111111";

function DebugZone({ users }) {
	const [enabled, setEnabled] = useState(false);

	const handleInputChange = (event) => {
		const { checked } = event.target;
		console.log("Checkbox checked:", checked);
		setEnabled(checked);
	};

	return (
		<>
			<Form.Check
				type={"switch"}
				label={`🐸 ${Object.keys(users).length} online`}
				style={{ textAlign: "left" }}
				onChange={handleInputChange}
			/>

			{enabled && (
				<>
					<pre style={{ textAlign: "left" }}>
						{Object.keys(users).map((key, index) => (
							<span key={index}>{`${index + 1}. ${key} 🟢\n`}</span>
						))}
					</pre>
				</>
			)}
		</>
	);
}

function Header({ config }) { 
	return (
		<>
			<h1>{config.title}</h1>
			<p>
				{config.welcomeMessage} 
				{" "}
				 <OverlayTrigger trigger="click" placement="right" overlay={popover}>
    <a href="#"><span>(?)</span></a>
  </OverlayTrigger>

				</p>

				<p>
				👉 <span style={{fontSize:"1em"}}><a href={`https://pump.fun/${CONTRACT_ADDRESS}`} target="_blank">{CONTRACT_ADDRESS}</a></span>
				</p>
{/*			<MusicPlayer />*/}
		</>
	);
}


const popover = (
  <Popover id="popover-basic">
    <Popover.Body>
      ribbit
      <br/>
      <br/>
				<a href={`https://pump.fun/${CONTRACT_ADDRESS}`} target="_blank">pump.fun</a>
    </Popover.Body>
  </Popover>
);

export default function World() {
	const [users, setUsers] = useState({});
	const { database, userId } = useDatabase();

	const [currentUserRef, setCurrentUserRef] = useState();
	const [userData, setUserData] = useState(null);

	const [config, setConfig] = useState({ title: "🙃🙃🙃" });

	useEffect(() => {
		if (currentUserRef == null) return;

		set(currentUserRef, userData);
	}, [userData, currentUserRef]);

	useEffect(() => {
		if (database == null) return;

		console.log("CREATEEEE");

		const userRef = ref(database, `users/${userId}`);
		const userDataVal = {
			id: userId,
			transform:{
			x: 300,
			y: 290,
			facing: 1,
			scaleX: 1,
			scaleY: 1,
		},
			visual: {
			imgIndex: 3,
			holding:"",

			},
			message: {
				text: "gm",
				timestamp: serverTimestamp(),
			},

			level: 1,
			xp: 0,

			timestamp: serverTimestamp(),
			//wallet: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
		};
		set(userRef, userDataVal);

		// update
		setUserData(userDataVal);
		setCurrentUserRef(userRef);

		const configRef = ref(database, "config");
		onValue(configRef, (snapshot) => {
			const snapshotVal = snapshot.val();
			//console.log("config: onValue", snapshotVal);
			setConfig(snapshotVal);
		});

		const starCountRef = ref(database, "users");
		/*onValue(starCountRef, (snapshot) => {
			const snapshotVal = snapshot.val();
			console.log("onValue", snapshotVal);
			//setUsers(snapshotVal);
		});*/

		onChildAdded(starCountRef, (snapshot) => {
			//console.log("onChildAdded", snapshot.val());
			console.log("Connected user (onChildAdded)", snapshot.val());

			const newUser = snapshot.val();
			const newUserId = newUser.id;
			const newUserRef = ref(database, `users/${newUserId}`);

			setUsers((prevState) => ({
				...prevState,
				[newUserId]: newUserRef,
			}));
		});

		onChildRemoved(starCountRef, (snapshot) => {
			console.log("onChildRemoved", snapshot.val());

			const newUser = snapshot.val();
			const newUserId = newUser.id;
			//const newUserRef = ref(database, `users/${newUserId}`);
			console.log("removing userid:", newUserId);

			/*setUsers((prevState) => ({
				...prevState,
				[newUserId]: null,
			}));*/

			setUsers((prevState) => {
				const { [newUserId]: _, ...rest } = prevState;
				return rest;
			});
		});

		onDisconnect(userRef).remove();
	}, [database, userId]);

	return (
		<>
			{config.enabled && (
				<>
					<SceneView users={users} />

					<Container>
						<Row>
							<p> </p>
						</Row>
						<Row>
							<Col md={{ span: 4, offset: 0 }}>
								<Header config={config} />

								<hr />
								<p>
								🚶 <strong>WASD/Arrow</strong> to move
								{/*<br/>
								<del>🤙 press <strong>E</strong> to interact</del>*/}
								</p>

								<DebugZone users={users} />
							</Col>
							<Col md={{ span: 5 }}>

								{(config.announcementMessage !== null && config.announcementMessage !== "") && <Alert variant={"warning"}><strong>msg from dev:</strong><br/>{config.announcementMessage}</Alert>}
								<PlayerUI
									userRef={currentUserRef}
									userData={userData}
									setUserData={setUserData}
								/>
							</Col>
						</Row>
					</Container>
				</>
			)}

			{!config.enabled && database != null && (
				<>
					<Container style={{ textAlign: "center" }}>
						<br/>
						<br/>
						<br/>
						<Image src={AssetDb.images.pepeDance} />
						<p>{config.offlineMessage}</p>
					</Container>
				</>
			)}

			{!config.enabled && database == null && (
				<>
					<Container style={{ textAlign: "center" }}>
						<br/>
						<br/>
						<br/>
						<Image src={AssetDb.images.pepeDance} />
						<p>im loading...</p>
					</Container>
				</>
			)}
		</>
	);
}
