/* eslint-disable import/no-anonymous-default-export */
const assets = {
	images: {
		egg: require("assets/environment/egg.png"),
		lilypad: require("assets/environment/lilypad.png"),
		pepeDance: require("assets/gifs/pepe-dance.gif"),
		pepeLargeSitting: require("assets/characters/pepe-large-sitting.png"),

		pepes: [
			require("assets/characters/pepe.png"),
			require("assets/characters/pepe-smoke.png"),
			require("assets/characters/pepe-bulge.png"),
			require("assets/characters/pepe-duck.png"),
			//require("assets/characters/pepe.png"),
		],

		holdables: {
			cross:require("assets/holdables/cross.png"),
			flower:require("assets/holdables/flower.png"),
		},

		blank: require("assets/blank.png"),
		hammer: require("assets/holdables/hammer.jpg"),
	},

	audio: {
		login: require("assets/audio/login.wav"),
	}
};

export default assets;