import React, { useState, useEffect } from "react";
import OnlinePlayer from "components/OnlinePlayer";
import AssetDb from "components/AssetDb";
import { Container, Stage, Sprite, Text, useTick } from "@pixi/react";
import { TextStyle } from 'pixi.js'


import {
	getDatabase,
	ref,
	set,
	off,
	onDisconnect,
	onValue,
	onChildAdded,
} from "firebase/database";



let time = 0.0;
const MAX_BONKS = 1000000000;


function waitSeconds(seconds) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

const textStyle = new TextStyle({
    fontFamily: "Comic Sans MS",
    align: 'center',
    // ... include other style properties as needed
  });

function BonkEgg({x, y, rotation=0.0}) {

	const [rot, setRot] = useState(rotation);
	const [bonks, setBonks] = useState(420);
	const [eggBroken, setEggBroken] = useState(false);

	const [bonkScale, setBonkscale] = useState(0);
	const [isBonkAnimating, setIsBonkAnimating] = useState(false);

	 useTick(delta => {
	 	time += delta*0.03;
	 	setRot(rotation + Math.cos(time)*0.07)
    // do something here
  })
	useEffect(()=>{

		const bonkCountRef = ref(getDatabase(), "global/bonks");

		// message
		onValue(bonkCountRef, (snapshot) => {
			const snapshotVal = snapshot.val();
			if (snapshotVal == null) return;
			setBonks(snapshotVal);
		});

		return ()=> {

			off(bonkCountRef, null);

		}
	},[]);

	const playAnimation = async()=> {
		if (isBonkAnimating)
			return;


		setIsBonkAnimating(true);
		setBonkscale(1);
		await waitSeconds(0.1);
		setIsBonkAnimating(false);
		setBonkscale(0);
	}

	useEffect(()=>{
		console.log("BONKKK", isBonkAnimating)
		if (!isBonkAnimating)
			playAnimation();

		setEggBroken(bonks < MAX_BONKS);
	}, [bonks]);


	return (
		<Container x={x} y={y}>

		

			{eggBroken &&
				<>
				<Text
				style={textStyle}
				text={`👊${bonks.toLocaleString()} / ${MAX_BONKS.toLocaleString()}\n(${(100 * bonks / MAX_BONKS)}%)`}
				y={-140 + bonkScale*5}
				anchor={{ x: 0.5, y: 0.5 }}
				scale={{ x: 0.5, y: 0.5 }}
			/>
				<Container scale={{x:1+bonkScale*0.12, y:1-bonkScale*0.1}}>
		<Sprite
				image={AssetDb.images.egg}
				scale={0.5}
				anchor={{ x: 0.5, y: 0.8 }}
				
				rotation={rot}
			/>
			</Container>
			</>}

			{!eggBroken &&
				<>
				<Text
				style={textStyle}
				text={`i was found\nbut r u?`}
				y={-140}
				anchor={{ x: 0.5, y: 0.5 }}
				scale={{ x: 0.5, y: 0.5 }}
			/><Container scale={{x:1+bonkScale*0.12, y:1-bonkScale*0.1}}>
		<Sprite
				image={AssetDb.images.pepeLargeSitting}
				scale={0.7}
				anchor={{ x: 0.5, y: 0.8 }}
				
				rotation={rot}
			/></Container></>}

			</Container>

		)
}

export default BonkEgg;