import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  isTyping: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setIsTyping: (state, action) => {
      state.isTyping = action.payload;
    },
  },
});

export const { setUserId, setIsTyping } = userSlice.actions;
export default userSlice.reducer;