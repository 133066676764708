import ReactAudioPlayer from "react-audio-player";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

const playlist = [
  require("assets/music/song1.mp3"),
  //require("assets/music/song2.mp3"),
  //require("assets/music/song3.mp3"),
].sort( () => .5 - Math.random() );

function MusicPlayer() {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

  useEffect(() => {
    // Reset the current track index when the playlist changes
    setCurrentTrackIndex(0);
  }, []);

  const handleGotoNextTrack = () => {
    // When the current track ends, move on to the next track
    setCurrentTrackIndex((currentTrackIndex + 1) % playlist.length);
  };

  const handleGotoPrevTrack = () => {
    // When the current track ends, move on to the next track
    setCurrentTrackIndex((playlist.length + currentTrackIndex - 1) % playlist.length);
  };

  return (
    <>
      {false  && <>
      <h1>Music Player</h1>
      <hr/>
      {playlist.map((asset, index) => (
        <React.Fragment key={index}>
          <span
            style={{
              fontWeight: index === currentTrackIndex ? "bold" : "normal",
            }}
          >
            {index === currentTrackIndex && "▶️"}
            {index}. {asset}
          </span>
          <br />
        </React.Fragment>
      ))}

      <hr />

      <Button onClick={handleGotoPrevTrack}>Prev</Button>
      {" "}
      <Button onClick={handleGotoNextTrack}>Next</Button>
      <hr />

      </>}
      <ReactAudioPlayer
        src={playlist[currentTrackIndex]}
        autoPlay={false}
        controls
        loop={false}
        volume={0.25}
        onEnded={handleGotoNextTrack}
      />
    </>
  );
}

export default MusicPlayer ;
