const levelToXP = (level) => {
	return level * 100;
};

const distance = (x0,y0,x1,y1) => {
	return Math.sqrt((x1-x0)*(x1-x0) + (y1-y0)*(y1-y0));
}

const distanceSqr = (x0,y0,x1,y1) => {
	return (x1-x0)*(x1-x0) + (y1-y0)*(y1-y0) ;
}


export {levelToXP, distance, distanceSqr}