import React, { useState, useEffect } from "react";
import OnlinePlayer from "components/OnlinePlayer";
import AssetDb from "components/AssetDb";
import BonkEgg from "components/BonkEgg";
import { Container, Stage, Sprite, useTick } from "@pixi/react";


/*
import {
	//getDatabase,
	ref,
	set,
	onDisconnect,
	onValue,
	onChildAdded,
} from "firebase/database";
*/




let time = 0.0;

function Lilypad({x, y, rotation=0.0}) {

	const [rot, setRot] = useState(rotation);

	 useTick(delta => {
	 	time += delta*0.015;
	 	setRot(rotation + Math.sin(time)*0.04)
    // do something here
  })

	return (
		<Sprite
				image={AssetDb.images.lilypad}
				scale={0.5}
				anchor={{ x: 0.5, y: 0.5 }}
				x={x} y={y}
				rotation={rot}
			/>

		)
}


function Egg({x, y, rotation=0.0}) {

	const [rot, setRot] = useState(rotation);

	 useTick(delta => {
	 	//time += delta*0.003;
	 	setRot(rotation + Math.cos(time)*0.07)
    // do something here
  })

	return (
		<Sprite
				image={AssetDb.images.egg}
				scale={0.5}
				anchor={{ x: 0.5, y: 0.8 }}
				x={x} y={y}
				rotation={rot}
			/>

		)
}


function Room({users}) {
	return (
		<>
			<Container>
			<Lilypad x={300} y={340}  />
			<Lilypad x={500} y={120} />
			<Lilypad x={1200} y={340} />
			<Lilypad x={1700} y={100} />
			<Lilypad x={2400} y={500} />

			{/*<Egg 
				x={300} y={330}
			/>*/}

			<Lilypad x={815} y={250}  rotation={3.14}/>
			<BonkEgg x={815} y={250-10}/>


				{Object.entries(users).map(([id, userRef]) => (
					userRef !== null &&
					<OnlinePlayer
						key={id}
						userRef={userRef}
						id={id}
					/>
				
				))}
				</Container>
		</>)
}

export default function SceneView({ users }) {
	const [stageWidth, setStageWidth] = useState(600);
	const [stageHeight, setStageHeight] = useState(600);

	useEffect(() => {
		const handleResize = () => {
			const windowWidth = document.body.clientWidth ;
			const windowHeight = window.innerHeight;
			//console.log("resize", windowWidth);
			setStageWidth(windowWidth);


			//setStageHeight(windowHeight);
		};

		window.addEventListener("resize", handleResize);
		handleResize();
	}, []);

	return (
		<>
			<Stage
				width={stageWidth}
				height={stageHeight}
				options={{ backgroundColor: 0x57f900, antialias: true }}
			>

			{/*<Container x={stageWidth*0.5 -selfUser.x}>*/}
				<Room users={users}/>
			</Stage>
		</>
	);
}
