import React, { useState, useEffect, useRef } from "react";
import MusicPlayer from "components/MusicPlayer";
import AssetDb from "components/AssetDb";
import { levelToXP, distance } from "utils/MathUtils";
import { incrementCounter } from "hooks/useDatabase";
import useKeypress from "react-use-keypress";

import { useSelector, useDispatch } from "react-redux";
import { setIsTyping } from "slice/CurrentUserSlice";

import {
	Form,
	InputGroup,
	Container,
	Row,
	Col,
	Button,
	Image,
	Card,
	ProgressBar,
} from "react-bootstrap";

import {
	//getDatabase,
	ref,
	set,
	get,
	child,
	onDisconnect,
	onValue,
	onChildAdded,
	onChildRemoved,
	serverTimestamp,
} from "firebase/database";

function PlayerInventory({userData, setUserData}) {
	const handleChangeOutfit = (event) => {
		setUserData((prevState) => ({
			...prevState,
			visual: {
				...prevState.visual,
				imgIndex:
					(prevState.visual.imgIndex + 1) %
					AssetDb.images.pepes.length,
			},
		}));
	};

	const handleBonk = (event) => {

		const transform = userData.transform;
		if (userData.transform.scaleX == 1) {

			const thresholdDistToEgg = 100;
			const eggX = 815;
			const eggY = 250-50;

			const distToEgg = distance(transform.x, transform.y, eggX, eggY);

			// bonk 
			//console.log(`BONKS FROM DIST: ${distToEgg}`);
			if (distToEgg < thresholdDistToEgg) {
				incrementCounter("global/bonks");
			}
		}

		setUserData((prevState) => ({
			...prevState,
			transform: {
				...prevState.transform,
				scaleX: prevState.transform.scaleX == 1.5 ? 1 : 1.5,
				scaleY: prevState.transform.scaleX == 1.5 ? 1 : 0.3,
			},
		}));
	};

	const handleHoldingCross = (event) => {
		setUserData((prevState) => ({
			...prevState,
			visual: {
				...prevState.visual,
				holding: prevState.visual.holding == "cross" ? "" : "cross",
			},
		}));
	};

	const handleHoldingFlower = (event) => {
		setUserData((prevState) => ({
			...prevState,
			visual: {
				...prevState.visual,
				holding: prevState.visual.holding == "flower" ? "" : "flower",
			},
		}));
	};


	return (
		<>
			<Card>
				<Card.Header>💼 your backpack</Card.Header>
				<Card.Body>
					<Col>
						<Button variant="outline-primary" onClick={handleChangeOutfit}>
							<Image
								style={{ width: 32, height: 32 }}
								src={AssetDb.images.pepes[0]}
							/>
						</Button>
						<Button variant="outline-primary" onClick={handleHoldingCross}>
							<Image
								style={{ width: 32, height: 32 }}
								src={AssetDb.images.holdables.cross}
							/>
						</Button>
						<Button variant="outline-primary" onClick={handleHoldingFlower}>
							<Image
								style={{ width: 32, height: 32 }}
								src={AssetDb.images.holdables.flower}
							/>
						</Button>
					</Col>
					<Col>
						<Button variant="outline-primary"  onClick={handleBonk}>
							<Image
								style={{ width: 32, height: 32 }}
								src={AssetDb.images.hammer}
							/>
						</Button>
						<Button variant="outline-primary" disabled>
							<Image
								style={{ width: 32, height: 32 }}
								src={AssetDb.images.blank}
							/>
						</Button>
						<Button variant="outline-primary" disabled>
							<Image
								style={{ width: 32, height: 32 }}
								src={AssetDb.images.blank}
							/>
						</Button>
					</Col>
					<Col>
						<Button variant="outline-primary" disabled>
							<Image
								style={{
									width: 32,
									height: 32
								}}
								src={AssetDb.images.blank}
							/>
						</Button>
						<Button variant="outline-primary" disabled>
							<Image
								style={{ width: 32, height: 32 }}
								src={AssetDb.images.blank}
							/>
						</Button>
						<Button variant="outline-primary" disabled>
							<Image
								style={{ width: 32, height: 32 }}
								src={AssetDb.images.blank}
							/>
						</Button>
					</Col>

					<Container hidden={true}>
						<Row>
							<Card>
								<Card.Header>🚬 cig</Card.Header>
								<Card.Body>
									(helps with nothing)
									<Button>use</Button>
								</Card.Body>
							</Card>
						</Row>
					</Container>
				</Card.Body>
			</Card>
		</>
	);
}

const OnlineTimer = ({ timestamp }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  const calculateElapsedTime = () => {
    const currentTime = new Date().getTime();
    const difference = currentTime - timestamp;
    setElapsedTime(difference);
  };

  useEffect(() => {
    const timer = setInterval(calculateElapsedTime, 1000);
    return () => clearInterval(timer);
  }, [timestamp]);

  const formatTime = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return `${days} days, ${hours % 24} hours, ${minutes % 60} minutes, ${seconds % 60} seconds`;
  };

  return (
    <div>
      <p>🟢 {formatTime(elapsedTime)}</p>
    </div>
  );
};

function DebugZone({ userData, timestamp }) {
	const [enabled, setEnabled] = useState(false);
	const isTyping = useSelector((state) =>
		state.user == null ? null : state.user.isTyping
	);

	const handleInputChange = (event) => {
		const { checked } = event.target;
		console.log("Checkbox checked:", checked);
		setEnabled(checked);
	};

	return (
		<>
			<Form.Check
				type={"switch"}
				label={`🎫`}
				style={{ textAlign: "left" }}
				onChange={handleInputChange}
			/>

			{enabled && (
				<>
					<OnlineTimer timestamp={timestamp}/>
					<pre style={{ textAlign: "left" }}>
						{JSON.stringify(userData, null, " ")}
					</pre>
				</>
			)}
		</>
	);
}

export default function PlayerUI({ userRef, userData, setUserData }) {
	const deltaMovementScale = 15;
	const dispatch = useDispatch();
	const isTyping = useSelector((state) => state.user.isTyping);
	const inputRef = useRef(null);

	const [timestampCreated, setTimestampCreate] = useState(0);

	const isInputFocused = () => {
		return inputRef.current === document.activeElement;
	};

	const moveUser = (deltaX, deltaY) => {
		if (deltaX === 0 && deltaY === 0) return;

		// add xp
		const xpToNextLevel = levelToXP(userData.level);

		setUserData((prevState) => ({
			...prevState,
			transform: {
				...prevState.transform,
				x: prevState.transform.x + deltaX * deltaMovementScale,
				y: prevState.transform.y + deltaY * deltaMovementScale,
				facing: deltaX === 0 ? prevState.transform.facing : deltaX,
			},
			xp:
				prevState.xp >= levelToXP(prevState.level)
					? 0
					: prevState.xp + 2,
			level:
				prevState.level +
				(prevState.xp >= levelToXP(prevState.level) ? 1 : 0),
		}));
	};

	useKeypress(["w", "a", "s", "d", "ArrowDown", "ArrowUp", "ArrowRight", "ArrowLeft", "Enter"], (event) => {
		if (!isInputFocused()) {
			if (event.key === "w") moveUser(0, -1);
			if (event.key === "a") moveUser(-1, 0);
			if (event.key === "s") moveUser(0, 1);
			if (event.key === "d") moveUser(1, 0);

			if (event.key === "ArrowUp") { event.preventDefault(); moveUser(0, -1);}
			if (event.key === "ArrowLeft") { event.preventDefault(); moveUser(-1, 0);}
			if (event.key === "ArrowDown") { event.preventDefault(); moveUser(0, 1);}
			if (event.key === "ArrowRight") { event.preventDefault(); moveUser(1, 0);}
		}

		if (event.key === "Enter") {
			console.log("ENTER");

			dispatch(setIsTyping(!isTyping));

			if (inputRef.current) {
				if (!isInputFocused()) inputRef.current.focus();
				else inputRef.current.blur();
			}
		}
	});

	const handleInputChange = (event) => {
		setUserData((prevState) => ({
			...prevState,
			message: {
				...prevState.message,
				text: event.target.value.substring(0, 75),
				timestamp: serverTimestamp(),
			},
		}));
	};

	useEffect(() => {
		console.log("CHANGEDTO: ", isTyping);
	}, [isTyping]);

	const testTimestamp = async () => {
		console.log("TIIMESTAMP GO");
		
		const timestampRef = child(userRef,"timestamp");
		 const snapshot = await get(timestampRef);
		 setTimestampCreate(snapshot.val());
		 //const date = new Date(snapshot.val());
		 //console.log("Actual Date:", date);
	}

	useEffect(() => {
		testTimestamp();
	}, []);

	return (
		<>
					<Row >
						<Col >
							(press ENTER)
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">
									💬
								</InputGroup.Text>
								<Form.Control
									placeholder="your message"
									aria-label="Message"
									aria-describedby="basic-addon1"
									ref={inputRef}
									onChange={handleInputChange}
									value={userData.message.text}
								/>
							</InputGroup>
							
							<Card style={{ display: 'none' }}>
								<Card.Body>
									you're {" "}
									<strong>level {userData.level}</strong>
									<ProgressBar
										variant="warning"
										now={
											(100 * userData.xp) /
											levelToXP(userData.level)
										}
										label={`level ${userData.level} (${
											userData.xp
										}/${levelToXP(userData.level)} XP)`}
									/>
								</Card.Body>
							</Card>
							<PlayerInventory userData={userData} setUserData={setUserData} />
							<DebugZone userData={userData} timestamp={timestampCreated}/>
						</Col>

					</Row>

			<br />
		</>
	);
}
