import React from 'react';
//import logo from './logo.svg';
//import { Counter } from './features/counter/Counter';
import './App.css';
import { Provider } from 'react-redux';
import store from 'slice/store';

import 'bootstrap/dist/css/bootstrap.min.css';


import Game from 'components/Game'

function App() {
  return (
      <Provider store={store}>
    <div className="App">
      <Game/>
      {/*<header className="App-header">
      </header>*/}
    </div>
     </Provider>
  );
}

export default App;
