//import { View, Text } from "react-native";

import React, { useState, useEffect } from "react";
import AssetDb from "components/AssetDb";

import { useDatabase } from "hooks/useDatabase";

//import * as PIXI from "pixi.js";
import { Container, Sprite, Text, useTick } from "@pixi/react";
import { TextStyle } from 'pixi.js'

import {
	//getDatabase,
	//ref,
	//set,
	off,
	//onDisconnect,
	onValue,
	child,
	//onChildAdded,
} from "firebase/database";

let time = 0.0;

const textStyle = new TextStyle({
    fontFamily: "Comic Sans MS",
    // ... include other style properties as needed
  });

export default function OnlinePlayer({ userRef, id }) {
	const [scaleX, setScaleX] = useState(0.1);
	const [scaleY, setScaleY] = useState(0.1);

	const [transform, setTransform] = useState(null);
	const [message, setMessage] = useState(null);
	const [visual, setVisual] = useState(null);

	const transformRef = child(userRef, "transform");
	const messageRef = child(userRef, "message");
	const visualRef = child(userRef, "visual");

	useEffect(() => {
		// transform
		onValue(transformRef, (snapshot) => {
			const snapshotVal = snapshot.val();
			if (snapshotVal == null) return;
			setTransform(snapshotVal);
		});

		// message
		onValue(messageRef, (snapshot) => {
			const snapshotVal = snapshot.val();
			if (snapshotVal == null) return;
			setMessage(snapshotVal);
		});

		// visual
		onValue(visualRef, (snapshot) => {
			const snapshotVal = snapshot.val();
			if (snapshotVal == null) return;
			console.log("VISUAL", snapshotVal);
			setVisual(snapshotVal);
		});

		/*
		// NO longer need entire data
		onValue(userRef, (snapshot) => {
			const snapshotVal = snapshot.val();

			if (snapshotVal == null) return;

			//console.log("onValue", snapshotVal);
			setUser(snapshotVal);
		});*/
		return () => {
			off(transformRef, null);
			off(messageRef, null);
			off(visualRef, null);
		};
	}, [userRef]);

	useTick((delta) => {
		time += delta * 0.05;

		const xScale = Math.sin(time) * 0.015;
		const yScale = Math.cos(time + 0.1) * 0.015;

		setScaleX(xScale);
		setScaleY(yScale);
	});

	return (
		(visual !== null &&
		<Container x={transform.x} y={transform.y}>
			<Sprite
				image={AssetDb.images.pepes[visual.imgIndex]}
				anchor={{ x: 0.5, y: 0.5 }}
				scale={{
					x: transform.facing * (0.25 + scaleX) * transform.scaleX,
					y: (0.25 + scaleY)* transform.scaleY,
				}}
			/>

			{visual !== null && visual.holding !== "" &&
			<Sprite
				image={AssetDb.images.holdables[visual.holding]}
				anchor={{ x: -0.1, y: 0.2 }}
				scale={{
					x: transform.facing * (0.4 ) * transform.scaleX,
					y: (0.4 )* transform.scaleY,
				}}
			/>
		}

			<Text
				style={textStyle}
				text={message.text}
				y={-43}
				anchor={{ x: 0.5, y: 0.5 }}
				scale={{ x: 0.5, y: 0.5 }}
			/>
		</Container>
		)
	);
}
