import { useEffect, useRef, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getDatabase,
  ref,
  set,
  //onDisconnect,
  onValue,
  //onChildAdded,
} from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcv1pq_kltn8JZdIS7dHsG555f3WYhKhU",
  authDomain: "comfyverse.firebaseapp.com",
  projectId: "comfyverse",
  storageBucket: "comfyverse.appspot.com",
  messagingSenderId: "1004527043831",
  appId: "1:1004527043831:web:56ee5c8c4092668849bff2",
  measurementId: "G-LKYPML8KZ5",
  databaseURL: "https://comfyverse-default-rtdb.firebaseio.com/",
};

const incrementCounter = async (path) => {
  const counterRef = ref(getDatabase(), path);

  // Get the current value of the counter and update it
  onValue(
    counterRef,
    (snapshot) => {
      const currentValue = snapshot.val();
      const newValue = (currentValue || 0) + 1;
      set(counterRef, newValue);
    },
    {
      onlyOnce: true,
    }
  );
};

const decrementCounter = async (path) => {
  const counterRef = ref(getDatabase(), path);

  // Get the current value of the counter and update it
  onValue(
    counterRef,
    (snapshot) => {
      const currentValue = snapshot.val();
      const newValue = (currentValue || 0) - 1;
      set(counterRef, newValue);
    },
    {
      onlyOnce: true,
    }
  );
};

const useDatabase = () => {
  const [userId, setUserId] = useState("");
  const [database, setDatabase] = useState(null);
  const hasClientInitialized = useRef(false);

  useEffect(() => {
    if (hasClientInitialized.current) return;

    hasClientInitialized.current = true;
    console.log("WORLD START");

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    //console.log(analytics);

    const db = getDatabase(app);
    //console.log(db);

    const auth = getAuth();

    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
        ///console.log("AUTH", auth);

        setUserId(auth.currentUser.uid);
        setDatabase(db);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorMessage);
        // ...
      });
  }, []);

  return {
    database,
    userId,
    incrementCounter,
    decrementCounter,
  };
};

export { useDatabase, incrementCounter, decrementCounter };
